import logoAlt from "../assets/images/logoAlt.png";
import tweet1 from "../assets/images/tweet1.png";
import tweet2 from "../assets/images/tweet2.png";
import comment from "../assets/images/svg/comment.svg";
import dot from "../assets/images/svg/dot.svg";
import likeFilled from "../assets/images/svg/likeFilled.svg";
import menu from "../assets/images/svg/menu.svg";
import retweet from "../assets/images/svg/retweet.svg";
import share from "../assets/images/svg/share.svg";
import verify from "../assets/images/svg/verify.svg";
import ReactModal from "react-modal";
import { useState } from "react";

const TweetModal = ({ modalIsOpen, setModalIsOpen }) => {
  const [ratio, setRatio] = useState(false);

  return (
    <ReactModal
      className="modalMain"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      contentLabel="Tweet Modal"
    >
      <div className="tweetModal">
        <div className="tweetModal__logoCon">
          <img src={logoAlt} alt="Logo text" />
          <h2 className="logoOpt">$ratio</h2>
        </div>

        <div className="tweetModal__main">
          <div className="tweetModal__main__tweets">
            <div className="tweetModal__main__tweets__item">
              <div className="tweetModal__main__tweets__item__left">
                <img src={tweet2} alt="profile" />
                <div className="tweetModal__main__tweets__item__left__line"></div>
              </div>
              <div className="tweetModal__main__tweets__item__right">
                <div className="tweetModal__main__tweets__item__right__info">
                  <div className="tweetModal__main__tweets__item__right__info__left">
                    <h3>Stephen A. Douglas</h3>
                    <img
                      className="tweetModal__main__tweets__item__right__info__left__img"
                      src={verify}
                      alt="verified"
                    />
                    <p>@SADouglasIL</p>
                    <img src={dot} alt="Dot" />
                    <p>21/8/1958</p>
                  </div>

                  <div className="tweetModal__main__tweets__item__right__info__right">
                    <img src={menu} alt="menu" />
                  </div>
                </div>
                <p className="tweetModal__main__tweets__item__right__tweet">
                  Abraham Lincoln is your typical Springfield lawyer. Two-faced
                  at his core.
                </p>
                <div className="tweetModal__main__tweets__item__right__actions space">
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={comment} alt="Comment" />
                    10.2k
                  </div>
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={retweet} alt="retweet" />
                    2.3K
                  </div>
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    {/* <img src={heart} alt="Comment" /> */}
                    <span className="material-symbols-rounded">favorite</span>
                    3K
                  </div>
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={share} alt="Comment" style={{ margin: 0 }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="tweetModal__main__tweets__item">
              <div className="tweetModal__main__tweets__item__left">
                <img src={tweet1} alt="profile" />
              </div>
              <div className="tweetModal__main__tweets__item__right">
                <div className="tweetModal__main__tweets__item__right__info">
                  <div className="tweetModal__main__tweets__item__right__info__left">
                    <h3>Abraham Lincoln</h3>
                    <img
                      src={verify}
                      alt="verified"
                      className="tweetModal__main__tweets__item__right__info__left__img"
                    />
                    <p>@RepAbeLincoln</p>
                    <img src={dot} alt="Dot" />
                    <p>21/8/1958</p>
                  </div>

                  <div className="tweetModal__main__tweets__item__right__info__right">
                    <img src={menu} alt="menu" />
                  </div>
                </div>
                <p className="tweetModal__main__tweets__item__right__tweet">
                  If I had another face, do you think I'd wear this one? + L +
                  ratio
                </p>
                <div className="tweetModal__main__tweets__item__right__actions">
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={comment} alt="Comment" />
                    10.2k
                  </div>
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={retweet} alt="retweet" />
                    2.3K
                  </div>
                  <div
                    className={
                      ratio
                        ? "tweetModal__main__tweets__item__right__actions__item last ratio"
                        : "tweetModal__main__tweets__item__right__actions__item last"
                    }
                  >
                    {/*  */}
                    {ratio ? (
                      <img src={likeFilled} alt="Comment" />
                    ) : (
                      <span
                        onClick={() => setRatio(true)}
                        className="material-symbols-rounded glow"
                      >
                        favorite
                      </span>
                    )}
                    {/* <div className="tweetModal__main__tweets__item__right__actions__item__num">
                      <div
                        className={
                          ratio
                            ? "tweetModal__main__tweets__item__right__actions__item__num__main active"
                            : "tweetModal__main__tweets__item__right__actions__item__num__main"
                        }
                      >
                        <p>2k</p>
                        <p>3k</p>
                        <p>4k</p>
                      </div>
                    </div> */}
                    {ratio ? "4k" : "2K"}
                  </div>
                  <div className="tweetModal__main__tweets__item__right__actions__item">
                    <img src={share} alt="share" style={{ margin: 0 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="tweetModal__main__info">
            {ratio
              ? "Ratio completed continue to ratio website"
              : "Ratio by Liking Abraham Lincoln’s reply to continue to the website"}
          </p>

          <div className="tweetModal__main__btn">
            <div style={{ overflow: "hidden", padding: 20 }}>
              <button
                className={ratio ? "btn show" : "btn"}
                onClick={() => setModalIsOpen(false)}
              >
                Continue{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default TweetModal;
