import logoAlt from "../assets/images/logoAlt.png";
import telegram from "../assets/images/svg/telegram.svg";
import x from "../assets/images/svg/x.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__logoCon">
        <h1 className="logoAlt small">$ratio</h1>
        <img style={{ marginTop: 50 }} src={logoAlt} alt="Logo text" />
      </div>

      <div className="footer__bottom">
        <h1 className="logo">$ratio</h1>

        <div className="footer__bottom__right">
          <a
            href="https://t.me/ratiosol"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: 20 }}
          >
            <img src={telegram} alt="" />
          </a>
          <a
            href="https://x.com/ratioonsol?s=21"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
