import { useEffect, useRef, useState } from "react";
import audio from "../assets/sound/ratio.mp3";
import playIcon from "../assets/images/svg/play.svg";
import muteIcon from "../assets/images/svg/mute.svg";

const Music = () => {
  const [play, setplay] = useState(false);

  const audioRef = useRef();

  const handleClickPlay = () => {
    audioRef.current.muted = false; // <-- sets the audio to unmuted so that the user may here the audio sounds.
    audioRef.current.play();
    setplay((curr) => !curr);
  };
  const handleClickPause = () => {
    audioRef.current.pause();
    setplay((curr) => !curr);
  };

  useEffect(() => {
    // initializeAudio();
  }, []);

  return (
    <div className="music">
      <div className="music__icon">
        {play ? (
          <img src={playIcon} alt="" onClick={handleClickPause} />
        ) : (
          <img src={muteIcon} alt="" onClick={handleClickPlay} />
        )}
      </div>

      <audio id="audio" muted ref={audioRef}>
        <source src={audio} />
      </audio>
    </div>
  );
};

export default Music;
