import HeroBg from "./HeroBg";
import down from "../assets/images/svg/down.svg";
import { useEffect, useState } from "react";

const Hero = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 20);

    // return () => {
    //   second
    // }
  }, []);

  return (
    <div className="hero">
      <HeroBg />
      <div className="hero__main">
        <div className="hero__main__top">
          <div className="hero__main__top__left">
            <h1 className="logo">$ratio</h1>
          </div>
          <div className="hero__main__top__right">
            <a
              href="https://t.me/ratiosol"
              target="_blank"
              rel="noopener noreferrer"
              className="hero__main__top__right__tel"
            >
              <span>Telegram</span>
            </a>
            <a
              href="https://x.com/ratioonsol?s=21"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>X</span>
            </a>
          </div>
        </div>

        <div className="hero__main__center">
          <div className="hero__main__center__title">
            <h1 className="logoAlt">$ratio</h1>
          </div>
          <p>Nobody’s safe join ratio or be ratio’d</p>

          <div className="hero__main__center__btn">
            <a
              href="https://jupiter-terminal.dexscreener.com/?inputMint=HtHEG31Nt3zYY16AHCyGg226ewzLGDzeHu2jQM2xU9Ag&outputMint=So11111111111111111111111111111111111111112"
              target="_blank"
              rel="noopener noreferrer"
            >
              {show && <button className="btn">Buy ratio</button>}
            </a>
          </div>
        </div>

        <div className="hero__main__bottom">
          <p>Scroll down</p>
          <img src={down} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
