import Home from "./Pages/Home";
import "./assets/styles/main.scss";
import "swiper/css/bundle";

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
