import frame1 from "../assets/images/frame1.png";
import frame2 from "../assets/images/frame2.png";
import frame3 from "../assets/images/frame3.png";
import frame4 from "../assets/images/frame4.png";
import frame5 from "../assets/images/frame5.png";

const HeroBg = () => {
  const firstcolum = [
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
    frame1,
  ];
  const secondcolum = [
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
    frame2,
  ];
  const thirdcolum = [
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
    frame3,
  ];
  const fourthcolum = [
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
    frame4,
  ];
  const fiftthcolum = [
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
    frame5,
  ];

  return (
    <div className="heroBg">
      <div className="heroBg__bg"></div>
      <div className="heroBg__column">
        {firstcolum.map((item, i) => {
          return (
            <div key={i} className="heroBg__column__item">
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
      <div className="heroBg__column alt two">
        {secondcolum.map((item, i) => {
          return (
            <div key={i} className="heroBg__column__item">
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
      <div className="heroBg__column three">
        {thirdcolum.map((item, i) => {
          return (
            <div key={i} className="heroBg__column__item">
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
      <div className="heroBg__column alt forth">
        {fourthcolum.map((item, i) => {
          return (
            <div key={i} className="heroBg__column__item">
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
      <div className="heroBg__column last">
        {fiftthcolum.map((item, i) => {
          return (
            <div key={i} className="heroBg__column__item">
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeroBg;
