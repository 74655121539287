import React, { useState } from "react";
import TweetModal from "../components/TweetModal";
import Music from "../components/Music";
import Hero from "../components/Hero";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import HallofFame from "../components/HallofFame";

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  return (
    <div className="home" style={{ position: "relative" }}>
      <TweetModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />
      <Music />

      {!modalIsOpen && (
        <>
          <Hero />

          <About />
          <Contact />
          <HallofFame />

          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;
